angular.module("app").factory('ProductService', function($resource, apiUrl, resourceInterceptor) {
    return $resource(apiUrl + "/product/:id", { id: "@id" }, {
        search: {
            url:apiUrl+'/cmsProduct',
            method: "GET",
            interceptor: resourceInterceptor
        },
        get: {
            method: "GET",
            interceptor: resourceInterceptor
        },
        deleteImg: {
            url:apiUrl+'/deleteImg',
            method: "POST",
            interceptor: resourceInterceptor
        },
        uploadImg: {
            url:apiUrl+'/uploadImgCMS',
            method: "PUT",
            headers: { 'Content-Type': undefined },
            transformRequest: angular.identity,
            interceptor: resourceInterceptor
        }
    });

})
