angular
  .module("app.config", [])
  //prod
  .constant("apiUrl", "https://yongcharoen.co.th/api")
  .constant("ecmUrl", "https://yongcharoen.co.th")
  //prod
  // .constant("apiUrl", "http://localhost:3109/api")
  // .constant("ecmUrl", "http://localhost:3002")
  //new-prodb
  // .constant("apiUrl", "https://test-ecm.conxo-ies.com/api")
  // .constant("ecmUrl", "https://test-ecm.conxo-ies.com")
  // // uat
  // .constant('apiUrl', 'http://192.168.0.18:3109/api')
  // .constant('ecmUrl', 'http://192.168.0.18')
  //local
  // .constant('apiUrl', 'http://10.10.2.10:3109/api')
  // .constant('ecmUrl', 'http://10.10.2.10:81/')
  .run(function ($rootScope, ecmUrl) {
    $rootScope.ecmUrl = ecmUrl;
  });
