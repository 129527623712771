angular.module("app").factory("BrandService", BrandService);

// CategoryService.$inject = ["$resource", "CommonCallback"];

function BrandService($resource, apiUrl, resourceInterceptor) {
  return $resource(
    "",
    {},
    {
      search: {
        url: apiUrl + "/brand/search",
        method: "POST",
        interceptor: resourceInterceptor,
      },
      updateStatus: {
        method: "POST",
        url: apiUrl + "/category/updateStatus",
        interceptor: resourceInterceptor,
      },
      uploadImg: {
        url: apiUrl + "/brand/uploadImgCMS",
        method: "PUT",
        headers: { "Content-Type": undefined },
        transformRequest: angular.identity,
        interceptor: resourceInterceptor,
      },
    }
  );
}
