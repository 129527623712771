angular.module("app").factory('ConfigService', function($resource, apiUrl, resourceInterceptor) {
    return $resource(apiUrl + "/config/:id", { id: "@id" }, {
        get: {
            method: "GET",
            interceptor: resourceInterceptor
        },
        add: {
            method: "POST",
            interceptor: resourceInterceptor
        },
        save: {
            method: "PUT",
            interceptor: resourceInterceptor
        }
    });

})
