angular.module("app")
	.controller('JobController', JobController)
	.controller('AddJobController', AddJobController)
	.controller('EditJobController', EditJobController);

// TestController.$inject = [];
var perPage = 10;
var maxProductsCount = 50;

function JobController($scope, $uibModal, $log, JobService, dialog) {
	
	$scope.search = search();
	$scope.deleteItem = deleteItem;

    function search() {
        JobService.search(function(res) {
            $scope.result = res.data;
        });
    }
    
    function deleteItem(row) {
    	JobService.delete({ id: row._id }, function() {
            search();
        })
    }

}

function AddJobController($scope, $log, JobService, dialog, ProductService, ProductGroupFactory, _, $sessionStorage, $state) {

    $scope.title = 'เพิ่มตำแหน่งงานที่รับสมัคร';
    $scope.model = {
       qty:1
    }

    $scope.submit = function(model) {

        JobService.add($scope.model, function() {
            dialog.show({
                type: 'success',
                message: 'บันทึกข้อมูลสำเร็จ'
            }, function() {
            	$state.go('app.job/search');
            });
        });
    }

}


function EditJobController($scope, $log, JobService, $stateParams, dialog, ProductGroupFactory, $sessionStorage, $state) {
    $scope.title = "แก้ไขตำแหน่งงานที่รับสมัคร"
    var id = $stateParams.id;


    JobService.get({ id: id }, function(res) {
        $scope.model = res;
    })

    $scope.submit = function(model) {
        
    	JobService.edit({ id: id }, model, function() {
            dialog.show({
                type: 'success',
                message: 'บันทึกข้อมูลสำเร็จ'
            }, function() {
            	$state.go('app.job/search');
            });
        });
    }


}

