angular
    .module('app')
    .controller('MainController', MainController);


function MainController($scope, $state, $location, $sessionStorage) {
    var mvm = this;
    // mvm.isLoginPage = function() {
    //     return $state.current.name.includes('login');
    // }

    if( !$sessionStorage.user ){
        $state.go('app.login');
    }
                


    var path = function() {
        return $location.path();
    }

    mvm.isLogin = false;
    $scope.$watch(path, function(newVal, oldVal) {

        mvm.isLogin = $location.path().includes('login');
        console.log("mvm.isLogin", mvm.isLogin);
        var contentSection = $('div.content-wrapper');
        var footer = $('#footer');

        if (mvm.isLogin) {
            contentSection.addClass('no-menu');
            footer.addClass('no-menu');
            console.log(footer);

        } else {
            contentSection.removeClass('no-menu');
            footer.removeClass('no-menu');
        }
    })



}
