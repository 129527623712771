angular.module("app").factory('BannerService', function($resource, apiUrl, resourceInterceptor) {
    return $resource(apiUrl + "/banner/:id", { id: "@id" }, {
        search: {
            url: apiUrl+"/cmsBanner",
            method: "GET",
            interceptor: resourceInterceptor
        },
        add: {
            method: "POST",
            headers: { 'Content-Type': undefined },
            transformRequest: angular.identity,
            interceptor: resourceInterceptor
        },
        get: {
            method: "GET",
            interceptor: resourceInterceptor
        },
        edit: {
            method: "PUT",
            headers: { 'Content-Type': undefined },
            transformRequest: angular.identity,
            interceptor: resourceInterceptor
        },
        delete: {
            method: "DELETE",
            interceptor: resourceInterceptor
        }
    });

})
