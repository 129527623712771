angular.module("app")
    .factory('ProductGroupFactory', ProductGroupFactory)
    .controller('SearchProductGroupController', SearchProductGroupController)
    .controller('AddProductGroupController', AddProductGroupController)
    .controller('EditProductGroupController', EditProductGroupController);

// TestController.$inject = [];
var perPage = 10;
var maxProductsCount = 50;

function SearchProductGroupController($scope, $uibModal, $log, ProductGroupService, dialog) {

    function search() {
        ProductGroupService.search(function(res) {
            $scope.result = res.data;
            $log.log(res);
        });
    }
    search();


    $scope.deleteItem = deleteItem;

    function deleteItem(row) {
        ProductGroupService.delete({ id: row._id }, function() {
            search();
        })
    }

    // $scope.productGroups = ProductGroupService.Search();
}

function AddProductGroupController($scope, $log, ProductGroupService, dialog, ProductService, ProductGroupFactory, _, $sessionStorage) {

    $scope.title = 'เพิ่มกลุ่มสินค้า';
    $scope.maxProductsCount = maxProductsCount;
    $scope.model = {
        products: []
    }

    $scope.getPaginatedItems = function(items, pageNo) {
        var pageNo = pageNo || 1,
            itemsPerPage = perPage,
            offset = (pageNo - 1) * itemsPerPage;
        $scope.paginatedItems = items.slice(offset, offset + itemsPerPage);
    }
    $scope.showSearchProductModal = function() {
        ProductGroupFactory.showSearchProductModal($scope.model.products, function(items) {
            $scope.model.products = items;
            $scope.getPaginatedItems(items)
        });
    }
    $scope.deleteItem = function(products, row) {
        ProductGroupFactory.deleteItem(products, row, function(items) {
            $scope.getPaginatedItems((items));
        });
    }
    $scope.paginatedItemsCriteria = {
        pageNo: 1,
        itemsPerPage: perPage,
    }




    $scope.submit = function(model) {
        if (model.products.length == 0) {
            dialog.show({
                type: 'danger',
                message: 'กรุณาเลือกสินค้า'
            });
            return;
        }
        var fd = new FormData();
        $scope.model.createdBy = $sessionStorage.userId;
        fd.append('headerIcon', $scope.headerIcon);
        fd.append('headerImg', $scope.headerImg);
        fd.append('model', JSON.stringify($scope.model));

        ProductGroupService.add(fd, function() {
            dialog.show({
                type: 'success',
                message: 'บันทึกข้อมูลสำเร็จ'
            }, function() {
                window.history.back();
            });
        });
    }



}


function EditProductGroupController($scope, $log, ProductGroupService, $stateParams, dialog, ProductGroupFactory, $sessionStorage) {
    $scope.title = "แก้ไขกลุ่มสินค้า"
    $scope.maxProductsCount = maxProductsCount;
    var productGroupId = $stateParams.id;
    $log.log(productGroupId);

    $scope.getPaginatedItems = function(items, pageNo) {
        var pageNo = pageNo || 1,
            itemsPerPage = perPage,
            offset = (pageNo - 1) * itemsPerPage;
        $scope.paginatedItems = items.slice(offset, offset + itemsPerPage);
    }
    $scope.showSearchProductModal = function() {
        ProductGroupFactory.showSearchProductModal($scope.model.products, function(items) {
            $scope.model.products = items;
            $scope.getPaginatedItems(items)
        });
    }
    $scope.deleteItem = function(products, row) {
        ProductGroupFactory.deleteItem(products, row, function(items) {
            $scope.getPaginatedItems((items));
        });
    }
    $scope.paginatedItemsCriteria = {
        pageNo: 1,
        itemsPerPage: perPage,
    }


    ProductGroupService.get({ id: productGroupId }, function(res) {
        $scope.model = res;
        $scope.getPaginatedItems($scope.model.products);
        $log.log(res);
    })

    $scope.submit = function(model) {
        if (model.products.length == 0) {
            dialog.show({
                type: 'danger',
                message: 'กรุณาเลือกสินค้า'
            });
            return;
        }
        $scope.model.updatedBy = $sessionStorage.userId;

        var fd = new FormData();
        fd.append('headerImg', $scope.headerImg);
        fd.append('headerIcon', $scope.headerIcon);
        fd.append('model', JSON.stringify($scope.model));

        ProductGroupService.edit({ id: productGroupId }, fd, function() {
            dialog.show({
                type: 'success',
                message: 'บันทึกข้อมูลสำเร็จ'
            }, function() {
                window.history.back();
            });
        });
    }


}

function ProductGroupFactory($log, $uibModal, ProductService, _, dialog) {
    this.showSearchProductModal = function(products, callback) {
        console.log('products', products)
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'searchProductModal.html',
            size: 'lg',
            resolve: {
                selectedItems: function() {
                    return angular.copy(products);
                }
            },
            controller: function($scope, $uibModalInstance, $log, _, selectedItems) {
                $scope.criteria = {
                    pageNo: 1,
                    itemsPerPage: perPage
                };

                $scope.unselectedItems = [];
                $scope.selectedItems = selectedItems;
                $scope.search = function() {
                    ProductService.search($scope.criteria, function(res) {
                        $scope.result = res;
                        $scope.criteria.totalItems = res.totalItems;
                        if ($scope.checkedAll) {
                            checkAll($scope.result.data, true);
                        }
                        $log.log(res);
                    });
                }


                $scope.search();
                $scope.cancel = function() {
                    $uibModalInstance.dismiss('');
                }
                $scope.ok = function() {
                    var criteria = angular.copy($scope.criteria);
                    criteria.itemsPerPage = $scope.criteria.totalItems;
                    criteria.pageNo = 1;
                    ProductService.search(criteria, function(res) {


                        if ($scope.checkedAll) {

                            $scope.selectedItems = res.data;
                            angular.forEach($scope.unselectedItems, function(ui) {
                                var removeItem = _.find($scope.selectedItems, function(si) {
                                    return si._id == ui._id;
                                })
                                if (removeItem) {
                                    _.pull($scope.selectedItems, removeItem);
                                }
                            });
                        } else {

                            $scope.unselectedItems = res.data;
                            angular.forEach($scope.selectedItems, function(si) {
                                var removeItem = _.find($scope.unselectedItems, function(ui) {
                                    return si._id == ui._id;
                                })
                                if (removeItem) {
                                    _.pull($scope.unselectedItems, removeItem);
                                }
                            });
                        }
                        console.log($scope.selectedItems);

                        $uibModalInstance.close({ selectedItems: $scope.selectedItems, unselectedItems: $scope.unselectedItems });
                    })
                }

                $scope.$watch('checkedAll', function(newVal, oldVal) {
                    if ($scope.result) {
                        checkAll($scope.result.data, newVal);
                    }

                })

                function checkAll(dataList, value) {

                    for (var i = 0; i < dataList.length; i++) {
                        if (!_.find($scope.unselectedItems, function(e) {
                                return e._id == dataList[i]._id
                            })) {
                            dataList[i].checked = value;

                        }
                    }

                }


                $scope.check = function(row) {

                    row.checked = !row.checked;
                    if (!row.checked) {
                        pushpop($scope.unselectedItems, $scope.selectedItems, row);
                    } else {
                        pushpop($scope.selectedItems, $scope.unselectedItems, row);
                    }
                }


                $scope.isSelected = function(row) {
                    var exist = $.grep($scope.selectedItems, function(e) {
                        return e._id == row._id;
                    })[0];

                    row.checked = exist != undefined;

                    return row.checked;
                }

                $scope.clearItem = function() {
                    $scope.selectedItems = [];
                    $scope.unselectedItems = [];
                }

                function pushpop(pushTo, popFrom, item) {
                    var exist = $.grep(pushTo, function(e) {
                        return e._id == item._id;
                    })[0];
                    if (!exist) {
                        pushTo.push(item)
                    }

                    for (var i = 0; i < popFrom.length; i++) {
                        if (popFrom[i]._id == item._id) {
                            popFrom.splice(i, 1);
                            break;
                        }
                    }
                }
            }
        });
        modalInstance.result.then(function(result) {
            console.log(result);

            for (var i = 0; i < result.selectedItems.length; i++) {
                var exist = _.find(products, function(e) {
                    return e._id == result.selectedItems[i]._id;
                })
                if (!exist) {
                    products.push({
                        _id: result.selectedItems[i]._id,
                        name: result.selectedItems[i].name
                    })
                }
            }

            for (var i = 0; i < result.unselectedItems.length; i++) {
                var exist = _.find(products, function(e) {
                    return e._id == result.unselectedItems[i]._id;
                })
                if (exist) {
                    _.pull(products, exist);

                }
            }
            console.log(products.length);

            if (products.length > maxProductsCount) {
                dialog.show({
                    type: 'error',
                    message: 'สามารถนำสินค้ามาจัดกลุ่มได้ไม่เกิน ' + maxProductsCount + ' ชิ้นเท่านั้น'
                });

            }
            // var cbData = angular.copy(products.slice(0, maxProductsCount));

            callback(products.slice(0, maxProductsCount));


        }, function() {

        });
    }

    this.deleteItem = function(items, row, callback) {
        console.log(items, row);
        var removeItem = _.find(items, function(i) {
            return i._id == row._id;
        })
        _.pull(items, removeItem);
        callback(items)
    }
    return this;
}
