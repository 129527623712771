angular
  .module("app", [
    "ui.router",
    "ui.bootstrap",
    "app.config",
    "ngCookies",
    "pascalprecht.translate",
    "ngAnimate",
    "ui.bootstrap",
    "ngResource",
    "ngTouch",
    "ngStorage",
    "AnimatedScroll",
    "angularValidator",
    "blockUI",
    "angular.ping",
    "colorpicker.module",
  ])
  .constant("_", window._);
