angular.module('app').factory('dialog', dialog);

function dialog($uibModal, $log, $rootScope) {
    this.show = function(content, callback) {
        content.type = content.type || 'info';
        if (!$rootScope.dialogIsOpen) {
            $rootScope.dialogIsOpen = true;
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/template/dialog.html',
                size: 'md',
                controller: function ctrl($scope, $uibModalInstance) {

                    $scope.content = content;
                    $scope.ok = ok;
                    switch (content.type) {
                        case 'info':
                            $scope.content.icon = "icon fa fa-info"
                            break;
                        case 'danger':
                            $scope.content.icon = "icon fa fa-exclamation-circle"
                            break;
                        case 'success':
                            $scope.content.type = 'primary';
                            $scope.content.icon = "icon fa fa-check"
                            break;
                        default:
                            break;
                    }

                    function ok() {
                        $uibModalInstance.dismiss('');
                    }
                }
            });

            modalInstance.result.then(function() {}, function() {})
                .finally(function() {
                    $rootScope.dialogIsOpen = false;
                    $log.log('closed dialog');
                    if (callback)
                        callback();

                })
                // .then(function() {

            // }, function() {
            //     $log.info('Modal dismissed at: ' + new Date());
            // });
        }
    }
    return this;
}
