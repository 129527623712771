angular.module("app")
    .factory('BannerFactory', BannerFactory)
    .controller('SearchBannerController', SearchBannerController)
    .controller('AddBannerController', AddBannerController)
    .controller('EditBannerController', EditBannerController);

// TestController.$inject = [];

function SearchBannerController($scope, $log, BannerService, dialog) {
    var vm = this;
    // $scope.result = [1, 2, 3, 4];
    $scope.deleteItem = deleteItem;

    function deleteItem(row) {
        BannerService.delete({ id: row._id }, function(res) {
            Search();
        })
    }

    function Search() {
        $scope.banners = BannerService.search(function(res) {
            $log.log(res);
            $scope.result = res.data;
        });
    }
    Search();

}

function AddBannerController(apiUrl, $scope, $log, BannerService, dialog, BannerFactory, $http, $sessionStorage) {
    $scope.model = {
        target: '_self',
        displayPosition: 1,
        order: 0
    }
    $scope.title = 'เพิ่มแบนเนอร์';

    $scope.submit = function(model) {
        model.banner1 = $scope.banner1;
        model.createdBy = $sessionStorage.user.userId;
        if (!model.banner1) return;
        // model.mBanner = $scope.mBanner;

        var fd = new FormData();
        fd.append('banner1', $scope.banner1);
        // fd.append('mBanner', $scope.mBanner);
        fd.append('model', JSON.stringify($scope.model));
        BannerService.add(fd, function() {
            dialog.show({
                type: 'success',
                message: 'บันทึกข้อมูลสำเร็จ'
            }, function() {
                window.history.back();
            });
        });
    }

    $scope.preview = BannerFactory.preview;


}


function EditBannerController($scope, $log, BannerService, $stateParams, dialog, BannerFactory, $sessionStorage) {
    $scope.title = "แก้ไขแบนเนอร์"
    var bannerId = $stateParams.id;
    $scope.preview = BannerFactory.preview;
    $scope.model = BannerService.get({ id: bannerId }, function(res) {
        $log.log(res);
    });

    $scope.submit = function(model) {
        model.banner1 = $scope.banner1;
        // model.mBanner = $scope.mBanner;
        model.updatedBy = $sessionStorage.user.userId;
        var fd = new FormData();
        fd.append('banner1', $scope.banner1);
        fd.append('model', JSON.stringify($scope.model));
        BannerService.edit({ id: bannerId }, fd, function() {
            dialog.show({
                type: 'success',
                message: 'บันทึกข้อมูลสำเร็จ'
            }, function() {
                window.history.back();
            });
        });
    }
}

function BannerFactory($uibModal) {
    this.preview = function(src, width, height) {
        console.log(src);
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'preview.html',
            windowClass: 'auto-width',
            controller: function($scope, $uibModalInstance) {
                $scope.src = src;
                $scope.width = width;
                $scope.height = height;
                $scope.close = function() {
                    $uibModalInstance.dismiss('');
                }
            }
        });
        modalInstance.result.then(function() {}, function() {});
    }

    return this;
}
