angular.module("app").factory('HomePopupService', function($resource, apiUrl, resourceInterceptor) {
    return $resource(apiUrl + "/homePopup/:id", { id: "@id" }, {
        get: {
            method: "GET",
            interceptor: resourceInterceptor
        },
        save: {
            method: "PUT",
            headers: { 'Content-Type': undefined },
            transformRequest: angular.identity,
            interceptor: resourceInterceptor
        }
    });

})
