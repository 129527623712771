angular.module("app").factory('JobService', function($resource, apiUrl, resourceInterceptor) {
    return $resource(apiUrl + "/job/:id", { id: "@id" }, {
        search: {
            url:apiUrl+'/job',
            method: "GET",
            interceptor: resourceInterceptor
        },
        add: {
            method: "POST",
            interceptor: resourceInterceptor
        },
        get: {
            method: "GET",
            interceptor: resourceInterceptor
        },
        delete: {
            method: "DELETE",
            interceptor: resourceInterceptor
        },
        edit: {
            method: "PUT",
            interceptor: resourceInterceptor
        }
    });

})
