angular
	.module('app')
	.component('templateHeader', {
		templateUrl: 'app/template/header.html',
		controller: HeaderController,
		controllerAs: 'vm'
	});

HeaderController.$inject = [];

function HeaderController() {
	var vm = this;
}