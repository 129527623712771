angular.module("app")
    .controller('Banner2Controller', Banner2Controller)

// TestController.$inject = [];

function Banner2Controller($scope, $uibModal, $log, Banner2Service, dialog, BannerFactory, $window ){

    var maxBannerQty = 8;

    function search() {
        Banner2Service.search(function(res) {

            $log.log(res.data.length);
            var bannerQty = res.data.length;
            var emptyBanner =[];
            for(var i =1;i<=maxBannerQty;i++){
                emptyBanner.push({
                        no:i,
                        target: '_self'
                    });
            };
            var bannerNoList = _.map(res.data, 'no');
            var toInsertBanners = _.filter(emptyBanner, function(item){
                return !_.includes(bannerNoList, item.no);
            });

            angular.forEach(toInsertBanners, function(e){
                res.data.push(e);
            })
            console.log(res.data);
            $scope.result = _.orderBy(res.data, ['no'], ['asc']);


            // if (bannerQty < maxBannerQty) {
            //     for (var i = bannerQty + 1; i <= maxBannerQty; i++) {
            //         $scope.result.data.push({
            //             no:i,
            //             target: '_self'
            //         })
            //     }
            // }
            // $log.log(res);
        });
    }
    search();


    $scope.openEditBanner2Modal = function(model) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'editBanner2Modal.html',
            size: 'md',
            controller: function($scope, $uibModalInstance, Banner2Service) {
                $scope.model = angular.copy(model);
                $scope.model._id = $scope.model.no;
                console.log(model);

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('');
                }
                $scope.ok = function() {
                    console.log($scope.model);
                    if(!$scope.banner2 && !model.src){
                        return;
                    }
                    $scope.model.updatedBy = "admin";
                    var fd = new FormData();

                    fd.append('banner2', $scope.banner2);
                    fd.append('model', JSON.stringify($scope.model));

                    Banner2Service.save(fd, function(res) {
                        $uibModalInstance.close();
                    })

                }
            }
        });
        modalInstance.result.then(function() {
            search();
        }, function() {});
    }
}
