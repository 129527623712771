angular
    .module('app')
    .component('templateMenu', {
        templateUrl: 'app/template/menu.html',
        controller: MenuController,
        controllerAs: 'vm'
    });

MenuController.$inject = ['$state'];

function MenuController($state) {
    var vm = this;
    vm.getStateName = function() {
        return $state.current.name
    }

}
