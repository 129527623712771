angular.module("app").factory('AuthenService', function($resource, apiUrl, resourceInterceptor) {
    return $resource("", null, {
        login: {
            url:apiUrl+'/cmsLogin',
            method: "POST",
            interceptor: resourceInterceptor
        }
    });

})
