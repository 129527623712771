angular.module("app")
    .controller('ConfigController', ConfigController)


function ConfigController($scope, $log, $state, ConfigService, dialog) {

    ConfigService.get(function(res) {

        $scope.model = res.data || {};
        $log.log(res);
    });
    $scope.submit = function(model) {
      model.createdBy = model.updatedBy = 'admin';
        ConfigService.save(model, function() {
            dialog.show({
                type: 'success',
                message: 'บันทึกข้อมูลสำเร็จ'
            });
        })
    }

}
