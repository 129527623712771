angular.module("app")
    .controller('HomePopupController', HomePopupController)


function HomePopupController($scope, $state, dialog, HomePopupService) {

  HomePopupService.get(function(res){
    $scope.model = res;
    console.log(res);
  })
    $scope.submit = function(model) {
        model.createdBy = model.updatedBy = 'admin';
        if(!$scope.homePopupImg && !model.src){
          // $('#file').click();
          return;
        }
        var fd = new FormData();
        fd.append('homePopupImg', $scope.homePopupImg);
        fd.append('model', JSON.stringify(model));
        HomePopupService.save(fd, function(res) {
            dialog.show({
                type: 'success',
                message: 'บันทึกข้อมูลสำเร็จ'
            });
        })

    }

}
