angular
  .module("app")
  .directive("confirmClick", confirmClick)
  .directive("fileinput", fileinput)
  .directive("focusOnInvalidForm", focusOnInvalidForm)
  .directive("btnBack", btnBack)
  .directive("number", number)
  .directive("fallbackSrc", fallbackSrc);

function confirmClick($uibModal, $log) {
  return {
    restrict: "A",
    link: function (scope, element, attr) {
      var clickAction = attr.confirmClick;

      element.bind("click", function (ev) {
        var confrimMessage = attr.confirmMessage || "ยืนยัน";
        var modalInstance = $uibModal.open({
          animation: true,
          templateUrl: "app/template/confirm.html",
          size: "sm",
          controller: function confrimController($scope, $uibModalInstance) {
            $scope.confrimMessage = confrimMessage;

            $scope.ok = ok;
            $scope.cancel = cancel;

            function ok() {
              $uibModalInstance.close();
            }

            function cancel() {
              $uibModalInstance.dismiss("cancel");
            }
          },
        });

        modalInstance.result.then(
          function () {
            scope.$eval(clickAction);
          },
          function () {
            $log.info("Modal dismissed at: " + new Date());
          }
        );
      });
    },
  };
}

function fileinput(dialog) {
  return {
    scope: {
      fileinput: "=",
      filepreview: "=",
    },
    link: function (scope, element, attributes) {
      element.bind("change", function (changeEvent) {
        if (changeEvent.target.files[0].size > 2048000) {
          var input = $(element);
          input.val("");
          dialog.show({
            type: "error",
            message: "ขนาดไฟล์ต้องน้อยกว่า 2MB",
          });
          return;
        }
        scope.fileinput = changeEvent.target.files[0];

        if (scope.fileinput) {
          var reader = new FileReader();
          reader.onload = function (loadEvent) {
            scope.$apply(function () {
              scope.filepreview = loadEvent.target.result;
            });
          };
          reader.readAsDataURL(scope.fileinput);
        } else {
          scope.$apply(function () {
            scope.filepreview = undefined;
          });
        }
      });
    },
  };
}

function focusOnInvalidForm() {
  return {
    restrict: "A",
    link: function (scope, elem) {
      // set up event handler on the form element
      elem.on("submit", function () {
        // find the first invalid element
        var firstInvalidRequired = elem[0].querySelector(
          "input.ng-invalid-required, select.ng-invalid-required"
        );
        // if we find one, set focus
        if (firstInvalidRequired) {
          //     var label = $(firstInvalidRequired).closest('.form-group').find("label").text();
          //     dialog.show("กรุณากรอก" + label, "danger");
          firstInvalidRequired.click();
          firstInvalidRequired.focus();
          return;
        }

        // var firstInvalidFormat = elem[0].querySelector('input.ng-invalid, select.ng-invalid');
        // // if we find one, set focus
        // if (firstInvalidFormat) {
        //     var label = $(firstInvalidFormat).closest('.form-group').find("label").text();
        //     dialog.show("กรุณากรอก" + label + "ให้ถูกต้อง", "danger");
        //     firstInvalidFormat.focus();
        //     return;
        // }
      });
    },
  };
}

function btnBack($window) {
  return {
    restrict: "A",
    link: function (scope, element, attrs) {
      element.on("click", function () {
        $window.history.back();
      });
    },
  };
}

function number() {
  return {
    require: "ngModel",
    restrict: "A",
    scope: {
      number: "=",
    },
    link: function (scope, elem, attrs, ngModel) {
      function inputValue(val) {
        if (val) {
          var digits = val.replace(/[^0-9.]/g, "");
          var maxDecimal = scope.number == undefined ? 0 : scope.number;
          var arrDigits = digits.split(".");
          if (arrDigits.length > 2) {
            digits = digits.substring(0, digits.length - 1);
          }
          // console.log(ngModel);

          if (arrDigits.length == 2) {
            if (arrDigits[1].length > maxDecimal)
              arrDigits[1] = arrDigits[1].substring(0, maxDecimal);
            digits = arrDigits[0] + "." + arrDigits[1];
          }

          if (digits !== val) {
            ngModel.$setViewValue(digits);
            ngModel.$render();
          }
          var result = parseFloat(digits);
          if (isNaN(result)) return "";

          return result;
        }
        return 0;
      }
      ngModel.$parsers.push(inputValue);

      elem.on("blur", function () {
        var val = elem.val();
        var lastChar = val.slice(-1);
        if (lastChar == ".") {
          val = val.slice(0, -1);
        }
        ngModel.$setViewValue(parseFloat(val).toString());
        ngModel.$render();
      });
    },
  };
}
function fallbackSrc() {
  var fallbackSrc = {
    link: function postLink(scope, iElement, iAttrs) {
      iElement.bind("error", function () {
        angular.element(this).attr("src", iAttrs.fallbackSrc);
      });
    },
  };
  return fallbackSrc;
}

angular.module("app").directive("onErrorSrc", function () {
  return {
    link: function (scope, element, attrs) {
      element.bind("error", function () {
        if (attrs.src != attrs.onErrorSrc) {
          attrs.$set("src", attrs.onErrorSrc);
        }
      });
    },
  };
});
