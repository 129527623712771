angular
  .module("app")
  .controller("SearchBrandController", SearchBrandController);

// TestController.$inject = [];
var perPage = 10;
var maxProductsCount = 50;

function SearchBrandController($scope, $uibModal, $log, BrandService, dialog) {
  init();
  search();

  function init() {
    $scope.criteria = {
      page: 1,
      size: 20,
    };
  }

  function search() {
    BrandService.search($scope.criteria, function (res) {
      $scope.result = res;
      $log.log($scope.result);
    });
  }

  $scope.search = function () {
    search();
  };

  $scope.changeEcomFlag = function (row) {
    var model = {
      _id: row.brandOid,
      ecommerceflag: row.ecommerceflag,
    };

    // CategoryService.updateStatus(model, function () {});
  };

  $scope.openUploadImgModal = function (row) {
    $scope.errorMessage = undefined;

    var data = {
      _id: row.brandOid,
    };

    var modalInstance = $uibModal.open({
      animation: true,
      templateUrl: "uploadBrandImgModal.html",
      size: "md",
      controller: function ($scope, $uibModalInstance, BrandService) {
        try {
          $scope.model = angular.copy(data);

          $scope.cancel = function () {
            $uibModalInstance.dismiss("");
          };

          $scope.ok = function () {
            $scope.errorMessage = undefined;

            if (!$scope.productImg) {
              return;
            }

            console.log($scope.productImg);

            var fileInput = document.getElementById("previewImg");

            var image = new Image();

            image.src = fileInput.src;

            //   if (image.height > 150 || image.width > 150) {
            //     $scope.errorMessage = "รูปภาพ ต้องมีขนาด 150x150 และ ไม่เกิน 100KB";
            //     return;
            //   }

            $scope.model.updatedBy = "admin";
            var fd = new FormData();

            fd.append("brandImg", $scope.productImg);
            fd.append("model", JSON.stringify($scope.model));

            console.log(fd);

            BrandService.uploadImg(fd, function (res) {
              $uibModalInstance.close();
              row.objLog.updatedDate = new Date();
              console.log(row);
            });
          };
        } catch (e) {
          console.log(e);
        }
      },
    });
    modalInstance.result.then(
      function () {
        search();
      },
      function () {}
    );
  };
}
