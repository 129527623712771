angular.module("app").config(routesConfig).run(stateChange);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
  $locationProvider.html5Mode(true);
  $urlRouterProvider.otherwise("/cms/login");

  $stateProvider
    .state("app", {
      url: "/cms",
      templateUrl: "app/template/main.html",
      controller: "MainController",
      controllerAs: "mvm",
    })
    .state("app.test", {
      url: "/test",
      templateUrl: "app/test/test.html",
      controller: "TestController",
      controllerAs: "vm",
    })
    .state("app.login", {
      url: "/login",
      templateUrl: "app/pages/login/login.html",
      controller: "LoginController",
    })
    .state("app.example", {
      url: "/example",
      templateUrl: "app/pages/example/example.html",
    })
    .state("app.banner/search", {
      url: "/banner/search",
      templateUrl: "app/pages/banner/banner.search.html",
      controller: "SearchBannerController",
    })
    .state("app.banner/add", {
      url: "/banner/add",
      templateUrl: "app/pages/banner/banner.save.html",
      controller: "AddBannerController",
    })
    .state("app.banner/edit", {
      url: "/banner/edit/:id",
      templateUrl: "app/pages/banner/banner.save.html",
      controller: "EditBannerController",
      params: {
        id: null,
      },
    })
    .state("app.banner2", {
      url: "/banner2",
      templateUrl: "app/pages/banner2/banner2.html",
      controller: "Banner2Controller",
    })
    .state("app.banner2/add", {
      url: "/banner2/add",
      templateUrl: "app/pages/banner2/banner2.save.html",
      controller: "AddBanner2Controller",
    })
    .state("app.banner2/edit", {
      url: "/banner2/edit/:id",
      templateUrl: "app/pages/banner2/banner2.save.html",
      controller: "EditBanner2Controller",
      params: {
        id: null,
      },
    })
    .state("app.productGroup/search", {
      url: "/productGroup/search",
      templateUrl: "app/pages/productGroup/productGroup.search.html",
      controller: "SearchProductGroupController",
    })
    .state("app.productGroup/add", {
      url: "/productGroup/add",
      templateUrl: "app/pages/productGroup/productGroup.save.html",
      controller: "AddProductGroupController",
    })
    .state("app.productGroup/edit", {
      url: "/productGroup/edit/:id",
      templateUrl: "app/pages/productGroup/productGroup.save.html",
      controller: "EditProductGroupController",
      params: {
        id: null,
      },
    })
    .state("app.job/search", {
      url: "/job/search",
      templateUrl: "app/pages/job/job.search.html",
      controller: "JobController",
    })
    .state("app.job/add", {
      url: "/job/add",
      templateUrl: "app/pages/job/job.save.html",
      controller: "AddJobController",
    })
    .state("app.job/edit", {
      url: "/job/edit/:id",
      templateUrl: "app/pages/job/job.save.html",
      controller: "EditJobController",
      params: {
        id: null,
      },
    })
    // .state('app.config', {
    //     url: '/config',
    //     templateUrl: 'app/pages/config/config.html',
    //     controller: 'ConfigController'
    // })
    .state("app.homePopup", {
      url: "/homePopup",
      templateUrl: "app/pages/homePopup/homePopup.html",
      controller: "HomePopupController",
    })
    .state("app.product/search", {
      url: "/product/search",
      templateUrl: "app/pages/product/product.search.html",
      controller: "SearchProductController",
    })
    .state("app.product/edit", {
      url: "/product/edit/:id",
      templateUrl: "app/pages/product/product.save.html",
      controller: "EditProductController",
    })
    .state("app.category/search", {
      url: "/category/search",
      templateUrl: "app/pages/category/category.search.html",
      controller: "SearchCategoryController",
    })
    .state("app.brand/search", {
      url: "/brand/search",
      templateUrl: "app/pages/brand/brand.search.html",
      controller: "SearchBrandController",
    });
}

function stateChange(
  $rootScope,
  $transitions,
  $sessionStorage,
  $state,
  $trace
) {
  $trace.enable("TRANSITION");
  $rootScope.$on(
    "$stateChangeStart",
    function (e, toState, toParams, fromState, fromParams) {
      if (!$sessionStorage.user && toState.name != "app.login") {
        e.preventDefault();
        $state.go("app.login");
      }
    }
  );
}
