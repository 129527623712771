angular.module('app')
    .config(function($httpProvider) {
        $httpProvider.interceptors.push('globalInterceptor');
    })
    .factory('globalInterceptor', function($sessionStorage) {
        return {
            // On request success
            request: function(config) {
                // console.log(config); // Contains the data about the request before it is sent.
                // $(".loader").show();
                // Return the config or wrap it in a promise if blank.
                // if ($sessionStorage.token)
                //     config.headers['Authorization'] = $sessionStorage.token.token_type + ' ' + $sessionStorage.token.access_token;

                return config || $q.when(config);
            }

            // // On request failure
            // requestError: function(rejection) {
            //     // console.log(rejection); // Contains the data about the error on the request.

            //     // Return the promise rejection.
            //     return $q.reject(rejection);
            // }
        }
    })
    .factory('resourceInterceptor', function($q, dialog, $location, $state) {
        return {

            responseError: function(rejection) {
                // console.log(rejection); // Contains the data about the error.

                var contentType = 'danger';
                if (rejection.status === -1) {
                    dialog.show({
                        message: "Unable to connect API Service.",
                        type: contentType
                    })
                } else if (rejection.status == 401) {
                    $state.go('app.login');
                } else {
                    dialog.show({ message: rejection.data.message, type: contentType })
                }

                // Return the promise rejection.
                return $q.reject(rejection);
            }
        };


    });
