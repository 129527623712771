angular.module("app")
    .controller('LoginController', LoginController)

function LoginController($scope, $state, AuthenService, dialog, $sessionStorage) {
    $sessionStorage.$reset();

    $scope.submit = function(model) {
        // console.log('xx')
        // console.log(model);
        AuthenService.login(model)
            .$promise.then(function(res) {
                $sessionStorage.user = res;
                console.log(res);
                $state.go('app');
            }, function(error) {
                dialog.show({ message: error.data.message, type: 'danger' })
            });
    }


    $scope.$on('$viewContentLoaded', function() {
         var contentSection = $('div.content-wrapper');
    var footer = $('#footer');
    contentSection.addClass('no-menu');
    footer.addClass('no-menu');
    });
}
